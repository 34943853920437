import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

const DefaultLayout = () => import('@/components/layouts/Default.vue')
const Auth = () => import('@/views/Auth.vue')
const Reservation = () => import('@/views/Reservation.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: DefaultLayout,
    name: 'default',
    redirect: '/auth',
    children: [
      {
        path: '/auth',
        name: 'Auth',
        component: Auth,
      },
      {
        path: '/reservation/:token?',
        name: 'Reservation',
        component: Reservation,
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach(async (to, from, next) => {
  // Крч, продавец с процентами будет лежать в сторе
  // Тут мы будем его звать и закидывать в стор 
  // И подмешивать токен в запросы
  if (to.name === 'Reservation') {
    const token = to.query.token

    try {
      const res = await store.dispatch('seller/getSeller', token)

      if (res.status === 401) {
        next('/auth')
      } else {
        store.commit('seller/setSeller', res.data)
        store.commit('seller/setToken', token)
      }
    } catch (err) {
      console.log(err)
    }
  }

  next()
})

export default router
