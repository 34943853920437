import axios from 'axios';

export const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {'accept': 'application/json', 'accept-language': 'ru-RU'}
})

export const shopHttp = axios.create({
  baseURL: process.env.VUE_APP_SHOP_BASE_URL,
  headers: {'accept': 'application/json'}
})