import { shopHttp } from '../../http/axios.js'

export async function getSeller({ commit }, token) {
  try {
    const sellerRes = await shopHttp.get('auth/user', { headers: { 'Authorization': 'Bearer ' + token } })
    let discounts = sellerRes.data.discounts

    if (discounts && discounts.length) {
      sellerRes.data.discounts = discounts.sort((a, b) => {
        return a - b
      })
    }
    
    return sellerRes
  } catch (err) {
    return err.response
  }
}

export function createShopOrder({ commit }, { data, token }) {
  const orderReq = shopHttp
    .post('auth/add-reservation', data, { headers: { 'Authorization': 'Bearer ' + token } })
    .then((res) => {
      const orderRes = res.data
      return orderRes
    })
  return orderReq
}

export function logout({ commit }, token) {
  const res = shopHttp
    .get('auth/logout', { headers: { 'Authorization': 'Bearer ' + token } })
    .then((res) => {
      return res.data
    })
  return res
}